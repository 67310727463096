var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getColaboradores}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1),(_vm.$root.acceso('CONTROL_COLABORADORES'))?_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":"","to":"/colaboradores/nuevo"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-account-plus")]),_vm._v("Dar de alta nuevo colaborador ")],1):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","small":"","disabled":_vm.selected.length == 0},on:{"click":function($event){$event.stopPropagation();return _vm.exportarTodos(_vm.selected)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Descargar seleccionados ")],1)],1),_c('div',{staticClass:"d-flex flex-wrap pb-2 mt-n2 align-center",staticStyle:{"gap":"4px"}},[_c('v-dialog',{attrs:{"max-width":"800"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"color":"primary","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-filter")]),_vm._v("Filtro permisos ")],1)]}}])},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Debe incluir todos los permisos: "),_c('v-radio-group',{attrs:{"row":"","dense":"","hide-details":""},model:{value:(_vm.filtroPermisosTodos),callback:function ($$v) {_vm.filtroPermisosTodos=$$v},expression:"filtroPermisosTodos"}},[_c('v-radio',{attrs:{"label":"Sí","value":true}}),_c('v-radio',{attrs:{"label":"No","value":false}})],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("Permisos")]),_c('v-divider'),_c('v-list',[_c('v-list-item-group',{attrs:{"multiple":""},model:{value:(_vm.filtroPermisos),callback:function ($$v) {_vm.filtroPermisos=$$v},expression:"filtroPermisos"}},_vm._l((_vm.descPermisos),function(permiso){return _c('v-list-item',{key:permiso.valor,attrs:{"dense":"","value":Number(permiso.valor)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(permiso.desc)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(permiso.permiso)+" ")])],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"fixed-checkbox",attrs:{"fixed-header":"","items":_vm.colaboradoresFiltrados,"headers":_vm.colaboradoresHeader,"show-select":"","item-key":"idColaborador","single-select":false,"calculate-widths":true,"loading":_vm.loading,"footer-props":{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        },"checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
        var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.colaboradores},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.esCanal",fn:function(ref){
        var item = ref.item;
return [(item.esCanal)?_c('v-chip',{staticClass:"v-chip--active",attrs:{"small":"","outlined":"","color":"primary"}},[_vm._v("Canal")]):_vm._e()]}},{key:"item.noFacturable",fn:function(ref){
        var item = ref.item;
return [(item.noFacturable)?_c('v-chip',{staticClass:"v-chip--active",attrs:{"small":"","outlined":"","color":"secondary"}},[_vm._v("No facturable")]):_vm._e()]}},{key:"item.esSubAgente",fn:function(ref){
        var item = ref.item;
return [(item.esSubAgente)?_c('v-chip',{staticClass:"v-chip--active",attrs:{"small":"","outlined":"","color":"warning"}},[_vm._v("SubAgente")]):_vm._e()]}},{key:"item.acciones",fn:function(ref){
        var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){_vm.verPermisos = item.permisos_b}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-shield-lock-outline")])],1)]}}],null,true)},[_vm._v(" Ver permisos ")]),(_vm.$root.acceso('DEV') && _vm.$root.acceso('CONTROL_COLABORADORES'))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"to":("/colaboradores/nuevo?idColaborador=" + (item.idColaborador)),"icon":"","color":"secondary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_vm._v(" Editar colaborador ")]):_vm._e()]}},{key:"item.empresa",fn:function(ref){
        var item = ref.item;
return [_c('empresa-chip',{attrs:{"value":item.empresa}})]}},{key:"item.entorno",fn:function(ref){
        var item = ref.item;
return [_c('status-chip',{attrs:{"value":item.entorno}})]}},{key:"item.Nombre",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"size":"10","color":item.userColor}}),_c('v-list-item',{staticClass:"my-n2",attrs:{"dense":""}},[_c('v-list-item-content',{staticClass:"py-0 my-n2"},[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.Nombre.split("__")[0])+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(item.Nombre.split("__")[1])+" ")])],1),_c('v-list-item-action',{staticClass:"d-flex flex-row",staticStyle:{"gap":"4px"}})],1)],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-btn',{staticClass:"mt-1",attrs:{"text":"","color":"primary"},on:{"click":_vm.exportarTodos}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Exportar todos ")],1),_c('v-dialog',{attrs:{"value":_vm.verPermisos != null,"max-width":"800px"},on:{"input":function (v) { return (v === false ? (_vm.verPermisos = null) : null); }}},[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-subheader',[_vm._v("Permisos")]),_c('v-divider'),_c('v-list',[_c('v-list-item-group',{attrs:{"value":_vm.permisosParsed,"multiple":""}},_vm._l((_vm.descPermisos),function(permiso){return _c('v-list-item',{key:permiso.valor,attrs:{"disabled":"","dense":"","value":Number(permiso.valor)},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(permiso.desc))]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(permiso.permiso)+" ")])],1)]}}],null,true)})}),1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }