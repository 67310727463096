<template>
  <v-container fluid>
    <div class="d-flex flex-wrap py-2">
      <v-btn class="ma-1" color="primary" small @click="getColaboradores">
        <v-icon left>mdi-reload</v-icon>Recargar
      </v-btn>
      <v-btn
        class="ma-1"
        color="primary"
        small
        to="/colaboradores/nuevo"
        v-if="$root.acceso('CONTROL_COLABORADORES')"
      >
        <v-icon left>mdi-account-plus</v-icon>Dar de alta nuevo colaborador
      </v-btn>

      <v-spacer />
      <v-btn
        @click.stop="exportarTodos(selected)"
        text
        color="primary"
        small
        :disabled="selected.length == 0"
      >
        <v-icon left>mdi-download</v-icon>Descargar seleccionados
      </v-btn>
    </div>

    <div class="d-flex flex-wrap pb-2 mt-n2 align-center" style="gap: 4px">
      <v-dialog max-width="800">
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ma-1" color="primary" small v-on="on" v-bind="attrs">
            <v-icon left>mdi-filter</v-icon>Filtro permisos
          </v-btn>
        </template>
        <v-card class="pa-4">
          <v-row>
            <v-col cols="12">
              Debe incluir todos los permisos:
              <v-radio-group row dense hide-details v-model="filtroPermisosTodos">
                <v-radio label="Sí" :value="true"></v-radio>
                <v-radio label="No" :value="false"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12">
              <v-subheader>Permisos</v-subheader>
              <v-divider />
              <v-list>
                <v-list-item-group v-model="filtroPermisos" multiple>
                  <v-list-item
                    dense
                    v-for="permiso in descPermisos"
                    :value="Number(permiso.valor)"
                    :key="permiso.valor"
                  >
                    <template v-slot:default="{ active }">
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>

                      <v-list-item-content>
                        <v-list-item-title>
                          {{
                            permiso.desc
                          }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{
                            permiso.permiso
                          }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </div>

    <v-card>
      <v-data-table
        fixed-header
        :items="colaboradoresFiltrados"
        :headers="colaboradoresHeader"
        show-select
        class="fixed-checkbox"
        item-key="idColaborador"
        v-model="selected"
        :single-select="false"
        :calculate-widths="true"
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
          itemsPerPageOptions: [10, 25, 50],
        }"
        checkbox-color="secondary"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters :headers="headers" :items="colaboradores" v-model="inlineFilters"></table-filters>
        </template>

        <template v-slot:item.esCanal="{ item }">
          <v-chip v-if="item.esCanal" small class="v-chip--active" outlined color="primary">Canal</v-chip>
        </template>

        <template v-slot:item.noFacturable="{ item }">
          <v-chip
            v-if="item.noFacturable"
            small
            class="v-chip--active"
            outlined
            color="secondary"
          >No facturable</v-chip>
        </template>

        <template v-slot:item.esSubAgente="{ item }">
          <v-chip
            v-if="item.esSubAgente"
            small
            class="v-chip--active"
            outlined
            color="warning"
          >SubAgente</v-chip>
        </template>

        <template v-slot:item.acciones="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                @click="verPermisos = item.permisos_b"
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
              >
                <v-icon>mdi-shield-lock-outline</v-icon>
              </v-btn>
            </template>
            Ver permisos
          </v-tooltip>

          <v-tooltip bottom v-if="$root.acceso('DEV') && $root.acceso('CONTROL_COLABORADORES')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                :to="`/colaboradores/nuevo?idColaborador=${item.idColaborador}`"
                v-on="on"
                v-bind="attrs"
                icon
                color="secondary"
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </template>
            Editar colaborador
          </v-tooltip>
        </template>

        <template v-slot:item.empresa="{ item }">
          <empresa-chip :value="item.empresa"></empresa-chip>
        </template>

        <template v-slot:item.entorno="{ item }">
          <status-chip :value="item.entorno"></status-chip>
        </template>

        <template v-slot:item.Nombre="{ item }">
					<div class="d-flex align-center">
            <v-avatar class="mr-2" size="10" :color="item.userColor" />
                      <v-list-item dense class="my-n2">
                        <v-list-item-content class="py-0 my-n2">
                          <v-list-item-title>
                            {{
                              item.Nombre.split("__")[0]
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{
                              item.Nombre.split("__")[1]
                            }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action class="d-flex flex-row" style="gap: 4px"></v-list-item-action>
                      </v-list-item>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <v-btn class="mt-1" text color="primary" @click="exportarTodos">
      <v-icon left>mdi-download</v-icon>Exportar todos
    </v-btn>

    <v-dialog
      :value="verPermisos != null"
      @input="(v) => (v === false ? (verPermisos = null) : null)"
      max-width="800px"
    >
      <v-card class="pa-4">
        <v-row>
          <v-col cols="12">
            <v-subheader>Permisos</v-subheader>
            <v-divider />
            <v-list>
              <v-list-item-group :value="permisosParsed" multiple>
                <v-list-item
                  disabled
                  dense
                  v-for="permiso in descPermisos"
                  :value="Number(permiso.valor)"
                  :key="permiso.valor"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>

                    <v-list-item-content>
                      <v-list-item-title>{{ permiso.desc }}</v-list-item-title>
                      <v-list-item-subtitle>
                        {{
                          permiso.permiso
                        }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { perColumnFilter, jsonToCsv, parseDate } from "@/utils/index.js";
import palette from "@/modules/impagados/services/colorPalette.js";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
    EmpresaChip: () => import("@/components/EmpresaChip.vue"),
    StatusChip: () => import("@/components/StatusChip.vue"),
  },
  computed: {
    colaboradoresFiltrados() {
      return this.colaboradores
        .filter((c) => {
          if (this.filtroPermisos.length == 0) return true;
          if (this.filtroPermisosTodos)
            return this.filtroPermisos.every(
              (perm) => (c.permisos_b) & (perm)
            );
          else
            return this.filtroPermisos.some(
              (perm) => (c.permisos_b) & (perm)
            );
        })
    },
    permisosParsed() {
      return this.descPermisos
        .map((p) => p.valor & this.verPermisos)
        .filter((p) => p > 0);
    },
  },
  data() {
    return {
      loading: false,
      inlineFilters: {},
      colaboradores: [],
      colaboradoresHeader: [
        // { text: '', value: 'idColaborador' },
        // { text: 'Correo', value: 'Login' },
        { text: "Nombre", value: "Nombre" },
        { text: "Canal", value: "esCanal", dataType: 'bool' },
        { text: "No Facturable", value: "noFacturable", dataType: 'bool' },
        { text: "SubAgente", value: "esSubAgente", dataType: 'bool' },
        {
          text: "Acciones",
          value: "acciones",
          filterable: false,
          sortable: false,
        },
        // { text: 'Nombre Contrato', value: 'NombreContrato' },
        { text: "Correo", value: "Correo" },
        { text: "Teléfono", value: "telefono" },
        { text: "Entorno", value: "entorno", dataType: 'select' },
        { text: "CIF", value: "cif" },
        { text: "Cod.Postal", value: "cp" },
        { text: "Ciudad", value: "ciudad" },
        { text: "Dirección", value: "direccion" },
        { text: "Provincia", value: "provincia" },
        { text: "Cuenta Corriente", value: "IBAN" },
        { text: "Portal", value: "empresa", dataType: "select" },
        // { text: '', value: 'permisos_b' },
        { text: "% Iva", value: "porcentajeIva" },
        { text: "% Irpf", value: "porcentajeIrpf" },
        { text: "Nº Contratos", value: "NContratos" },
        // { text: 'ID', value: 'IdAgente' },
        // { text: 'Parent', value: 'ancestor_id' },
      ].map((header) => ({
        class: "text-no-wrap sticky-header",
        cellClass: "pa-2 text-no-wrap",
        filter: (value) =>
          perColumnFilter(
            value,
            this.inlineFilters[header.value],
            header.dataType
          ),
        dataType: "text",
        ...header,
      })),
      selected: [],
      filtroPermisos: [],
      filtroPermisosTodos: false,
      descPermisos: [],
      verPermisos: null,
    };
  },
  methods: {
    exportarTodos(items) {
      const getDatatype = (key) =>
        (this.colaboradoresHeader.find((h) => h.value == key) || {}).dataType;

      if (!Array.isArray(items))
        items = this.colaboradores.filter((con) =>
          Object.entries(con).every(([key, value]) =>
            perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
          )
        );

      const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

      jsonToCsv(items, csvHeaders, 'COLABORADORES_' + parseDate(Date.now(), false, false));
    },
    getColaboradores() {
      this.loading = true;
      this.selected = [];

      axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/colaboradores.php`,
        params: {
          token: this.$store.getters.getJwtEmpresa,
        },
      }).then((res) => {
        this.colaboradores = res.data.map((c) => {
          c.Nombre = [...new Set([c.NombreContrato, c.Nombre])].join("__");
          c.esCanal = c.ancestor_id == null;
          c.esSubAgente = c.ancestor_id != null;
          c.noFacturable = c.ancestor_id == null && c.IBAN == null;
          c.userColor = palette[ ((c.idColaborador % palette.length) + palette.length) % palette.length ];
          console.log(c);
          return c;
        });
        this.loading = false;
      });
    },
    async getDescPermisos() {
      let { data: permisos } = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_OUR_API_URL}/permisos.php`,
      });

      permisos.forEach(({ permiso, valor, desc }) =>
        this.descPermisos.push({ permiso, valor, desc })
      );
      this.descPermisos = this.descPermisos.sort((a, b) =>
        Number(a.valor) > Number(b.valor) ? 1 : -1
      );
    },
  },
  mounted() {
    this.getColaboradores();
    this.getDescPermisos();
  },
};
</script>